import {createRouter, createWebHistory} from 'vue-router'
import AuthService from '@/api/services/auth'

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('../views/MainPage.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/LoginPage.vue')
    },
    {
        path: '/load',
        name: 'ClearForLoading',
        component: () => import('../views/ClearView.vue'),
        children: [
            {
                path: '',
                name: 'LoadRating',
                component: () => import('../views/load/LoadPage.vue'),
            },
            {
                path: 'many',
                name: 'LoadPackGames',
                component: () => import('../views/load/LoadPack.vue'),
            },
        ]
    },
    {
        path: '/teams',
        name: 'ClearRouteTeam',
        component: () => import('../views/ClearView.vue'),
        children: [
            {
                path: 'list',
                name: 'Teams',
                component: () => import('../views/teams/TeamsPage.vue'),
            },
            {
                path: 'new-rank',
                name: 'NewRanksTeams',
                component: () => import('../views/teams/NewRanks.vue'),
            },
            {
                path: 'join',
                name: 'JoinTeams',
                component: () => import('../views/teams/JoinTeamsPage.vue'),
            },
            {
                path: 'points-transferring',
                name: 'PointsTransferringTeam',
                component: () => import('../views/teams/PointsTransferringTeamPage.vue'),
            },
            {
                path: ':teamId',
                name: 'TeamDetail',
                component: () => import('../views/teams/DetailPage.vue'),
            },
            {
                path: ':teamId/legend-edit',
                name: 'LegendEditPage',
                component: () => import('../views/teams/LegendEditPage.vue'),
            },
            {
                path: ':teamId/connected-team',
                name: 'ConnectedTeamsView',
                component: () => import('../views/teams/ConnectedTeams.vue'),
            },
        ]
    },
    {
        path: '/rating',
        name: 'Rating',
        component: () => import('../views/rating/RatingPage.vue'),
    },
    {
        path: '/settings',
        name: 'ClearViewSeason',
        component: () => import('../views/ClearView.vue'),
        children: [
            {
                path: 'season',
                name: 'SeasonPage',
                component: () => import('../views/settings/SeasonPage.vue'),
            },
            {
                path: 'achievements',
                name: 'Achievements',
                component: () => import('../views/settings/AchievementsPage.vue'),
            },
            {
                path: 'start-rating',
                name: 'StartRating',
                component: () => import('../views/settings/StartRating.vue'),
            },
            {
                path: 'rank',
                name: 'RankList',
                component: () => import('../views/settings/RankListPage.vue'),
            },
        ]
    },
    {
        path: '/games',
        name: 'ClearViewGames',
        component: () => import('../views/ClearView.vue'),
        children: [
            {
                path: '',
                name: 'GameListPage',
                component: () => import('../views/games/GameListPage.vue'),
            },
            {
                path: ':gameId',
                name: 'GameDetailPage',
                component: () => import('../views/games/GameDetailPage.vue'),
            },
        ]
    },

    // {
    //   path: '*',
    //   name: 'NotFoundPage',
    //   component: () => import('../views/error/NotFound.vue'),
    // },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to) => {
    if (to.query.token) {
        await AuthService.loginByToken(to.query.token)
            .then((response) => {
                localStorage.setItem('ratingUser', JSON.stringify(response.data.result.user))
            }).finally(() => {
                router.replace({name: 'Teams'});
            });
    } else if ((!localStorage.ratingUser) && (to.name !== 'Login')) {
        return {name: 'Login'}
    }
})

export default router
