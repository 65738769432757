import http from "../api"

const path = '/auth'

class AuthService {
    login(data) {
        return http.post(`${path}/login`, data);
    }

    loginByToken(token) {
        return http.post(`${path}/loginByToken`, {token: token});
    }
}

export default new AuthService();