import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_URL,
    headers: {
        "Content-type": "application/json"
    },
})

apiClient.interceptors.request.use(config => {
    const userData = JSON.parse(localStorage.getItem('ratingUser'))

    if (userData?.token) {
        config.headers = config.headers || {}
        config.headers.Authorization = userData.token ? `Bearer ${userData.token.split('|')[1]}` : ''
    }

    return config
});

export default apiClient;